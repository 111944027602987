// 'eff-styles form-field select
$blue-color: #3c8fee;

$white-0: #ffffff;
$white-100: #f9f9f9;
$white-500: #ededed;
$white-600: #d8d8d8;
$white-700: #a8a8a8;

$black-400: #383b3f;

$grey-100: #a1a1a2;

.eff {
	.eff-form-field {
		&.eff-form-field--select {
			&[appearance='fill'] {
				border-radius: 4px;
				border: none;
				transition: 200ms;

				.mat-form-field-label-wrapper .mat-form-field-label {
					display: flex;
					line-height: 125%;
				}

				.mat-form-field-wrapper {
					height: 100%;
					padding-bottom: 0;
				}

				.mat-form-field-flex {
					height: 100%;
					border-radius: 4px;
					padding: 0 14px;
				}

				.mat-input-element {
					margin: 0;
					line-height: initial;
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: $white-700;
				}

				.mat-form-field-suffix {
					margin-left: 4px;
				}

				.mat-form-field-prefix {
					margin-right: 4px;
				}

				.mat-form-field-infix {
					display: flex;
					border-top: 0;
					width: 100%;
					padding: 0;
				}

				.mat-form-field-underline {
					display: none;
				}
				.mat-select-arrow-wrapper {
					transform: translateY(0);
				}

				&[reskin='true'] {
					.mat-button-wrapper {
						transform: translateY(-7px) !important;
					}
				}

				&.mat-form-field-disabled {
					.mat-select-arrow-wrapper {
						display: none;
					}
				}

				.mat-select-value {
					color: inherit;
				}

				.mat-select-trigger {
					align-items: center;
				}
				&.mat-focused .mat-select-arrow {
					transform: rotate(180deg);
				}
			}

			// Size small
			&[size='small'] {
				height: 24px;
				line-height: 15px;
				font-size: 11px;
				font-weight: 400;
			}

			// Size medium
			&[size='medium'] {
				height: 32px;
				line-height: 15px;
				font-size: 11px;

				.mat-form-field-flex {
					padding: 0 12px;
				}

				.mat-form-field-suffix {
					margin: 0 12px 0 0;
				}

				.mat-form-field-label-wrapper {
					padding-top: 0;

					.mat-form-field-label {
						padding-top: 8.5px;
					}
				}
			}

			// Size regular
			&[size='regular'] {
				height: 40px;
				font-size: 14px;

				.mat-form-field-flex {
					padding: 4px 12px 6px;
				}

				.mat-form-field-prefix {
					line-height: 25px;
				}
			}

			// Size large
			&[size='large'] {
				height: 46px;
				font-size: 14px;

				.mat-select {
					margin-top: 4px;
				}

				.mat-select-panel {
					margin-top: 36px;
				}

				.mat-form-field-infix {
					margin-top: 16px;
				}

				// country select
				&.ds-country-select {
					.mat-form-field-infix {
						margin-top: 10px;
					}
				}

				.mat-input-element {
					padding-bottom: 6px;
				}

				.mat-form-field-label-wrapper {
					padding-top: 0;

					.mat-form-field-label {
						padding-top: 2px;
					}
				}
			}

			&:not(.mat-form-field-disabled).mat-focused.mat-primary {
				outline: 2px solid $blue-color;
			}

			&:not(.mat-form-field-disabled):hover {
				outline: 2px solid $white-600;
			}

			// Color theme dark
			&[color='dark'] {
				background-color: $black-400;
				color: $white-100;

				&.mat-form-field-invalid {
					background: rgba(235, 87, 87, 0.2);
					outline: 2px solid #eb5757;
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: $white-700;
				}

				.mat-input-element {
					color: $white-100;

					&::placeholder {
						color: $grey-100;
					}
				}

				input.mat-input-element[data-com-onepassword-filled='light'],
				input.mat-input-element:autofill {
					-webkit-text-fill-color: $white-100;
					padding-bottom: 0;
					box-shadow: 0 0 0 30px $black-400 inset;
				}

				&:not(.mat-form-field-disabled):hover {
					background-color: #414449;
				}

				&:not(.mat-form-field-disabled):active {
					color: $grey-100;
				}

				&:not(.mat-form-field-disabled):hover,
				&:not(.mat-form-field-disabled):active {
					outline: 2px solid $blue-color;
				}

				.mat-select-arrow {
					color: $grey-100;
				}

				.mat-form-field-label {
					color: $white-700;
				}
				.mat-select-placeholder {
					color: $grey-100;
				}

				&.mat-form-field-disabled {
					background-color: var(--black-400);
					color: var(--black-100);

					.mat-form-field-label {
						color: var(--black-200);
					}

					.mat-select-placeholder,
					.mat-input-element {
						color: var(--black-100);
					}
				}
			}

			// Color theme Light
			&[color='light'] {
				background-color: $white-500;
				color: $black-400;

				&.mat-form-field-invalid {
					background: rgba(235, 87, 87, 0.2);
					outline: 2px solid #eb5757;
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: #646464;
				}

				&:not(.mat-form-field-disabled):active {
					color: $grey-100;
					outline: 2px solid $blue-color;
				}

				&:not(.mat-form-field-disabled):hover {
					color: $black-400;
					outline: 1px solid $white-600;
				}

				.mat-select-arrow {
					color: $black-400;
				}

				&.mat-form-field-disabled {
					background-color: var(--white-400);
					color: var(--black-100);

					.mat-select-placeholder,
					.mat-input-element,
					.mat-form-field-label {
						color: var(--black-100);
					}
				}
			}

			.mat-raised-button.mat-primary.mat-button-disabled {
				background-color: $white-700;
				color: $white-0;
			}
		}
	}

	.eff-select-panel {
		.mat-option {
			border-radius: 0;
			padding-left: 8px;
			font-size: 11px;
			margin: 4px 0;
		}
		// color theme Dark
		&.eff-select-panel--color-dark {
			background: #222426;

			.mat-select-content {
				background: #222426;
			}

			.mat-option {
				color: $white-0;
				&:hover {
					background: $black-400;
				}
			}
		}
		// color theme light
		&.eff-select-panel--color-light {
			.mat-option {
				&.mat-active {
					background: rgba(104, 165, 228, 0.2);
				}
				&:hover {
					background: $white-500;
				}
				&:disabled {
					color: #bfbfc1;
					cursor: default;
				}
			}
		}
		// size small
		&.eff-select-panel--size-small {
			.mat-option {
				height: 24px;
			}
		}
		// size medium
		&.eff-select-panel--size-medium {
			.mat-option {
				height: 32px;
			}
		}
		// size large
		&.eff-select-panel--size-large {
			.mat-option {
				line-height: 40px;
				font-size: 14px;
				height: 40px;
			}
		}
	}
	.mat-select-panel-wrap {
		padding-left: 4px;
		padding-right: 4px;
	}
}
