.scrollable--y {
	-webkit-overflow-scrolling: touch;
	max-height: 100%;
	overflow: hidden;
	overflow-y: auto;
}

.scrollable--y::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

.scrollable--y::-webkit-scrollbar:window-inactive {
	opacity: 0;
}

.scrollable--y::-webkit-scrollbar-thumb {
	background: #e0e5ee;
	border-radius: 8px;
	box-shadow: #a8b7c7 0 0 0 1px inset;
}

.scrollable--y::-webkit-scrollbar-track {
	background: #a8b7c7;
}
