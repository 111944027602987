// 'eff-styles form-field input
// to add label in large size use mat-label, in medium and small use matPrefix
// to set color theme and input size use attributes 'size' (small, medium, large) and 'color' (dark, light)
$blue-color: #3c8fee;

.eff {
	.eff-form-field {
		&.eff-form-field--input {
			&[appearance='fill'] {
				border-radius: 4px;
				border: none;
				transition: 0ms;
				.mat-form-field-flex {
					padding: 0 0 0 12px;
					align-items: center;
					height: 100%;
					border-radius: 4px;
				}

				.mat-form-field-label-wrapper {
					margin-top: 0;
				}

				.mat-form-field-label-wrapper .mat-form-field-label {
					display: flex;
					line-height: 125%;
				}

				.mat-input-element {
					margin: 0;
					line-height: initial;
				}

				.mat-form-field-wrapper {
					height: 100%;
					padding-bottom: 0;
				}

				.mat-form-field-suffix {
					margin-left: 4px;
				}

				.mat-form-field-prefix {
					margin-right: 4px;
				}

				.mat-form-field-infix {
					display: flex;
					border-top: 0;
					width: 100%;
					padding: 0;
					height: 100%;
				}

				.mat-form-field-underline {
					display: none;
				}
			}

			&[color='dark'] {
				background-color: #383b3f;
				color: #f9f9f9;
				&.mat-form-field.mat-focused .mat-form-field-label {
					color: #a8a8a8;
				}

				&.mat-form-field-invalid {
					background: rgba(235, 87, 87, 0.2);
					outline: 2px solid #e46868;

					.mat-form-field-label {
						color: #e46868;
					}
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: #a8a8a8;
				}

				.mat-input-element {
					color: #f9f9f9;

					&::placeholder {
						color: #a1a1a2;
					}
				}

				&:not(.mat-form-field-disabled):hover {
					background: #464a4f;
				}

				&:not(.mat-form-field-disabled):focus {
					color: #a1a1a2;
					outline: 2px solid $blue-color;
				}

				&:not(.mat-form-field-disabled).mat-focused {
					outline: 2px solid $blue-color;
				}

				.mat-form-field-label {
					color: #a1a1a2;
				}
				&.mat-form-field-disabled {
					background-color: var(--black-400);

					.mat-input-element,
					.mat-input-element::placeholder,
					.mat-form-field-label {
						color: var(--black-200);
						-webkit-text-fill-color: var(--black-200);
						opacity: 1;
					}
				}
			}

			&[color='light'] {
				color: #646464;
				background-color: #ededed;
				&.mat-form-field.mat-focused .mat-form-field-label {
					color: #646464;
				}

				&.mat-form-field-invalid {
					background: rgba(235, 87, 87, 0.2);
					outline: 2px solid #eb5757;
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: #646464;
				}

				&:not(.mat-form-field-disabled).mat-focused {
					color: #a1a1a2;
					outline: 2px solid $blue-color;
				}

				&:not(.mat-form-field-disabled):hover {
					color: #383b3f;
					outline: 2px solid #d8d8d8;
				}
				&.mat-form-field-disabled {
					background-color: var(--white-400);

					.mat-input-element,
					.mat-input-element::placeholder,
					.mat-form-field-label {
						color: var(--black-100);
						-webkit-text-fill-color: var(--black-100);
						opacity: 1;
					}
				}
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-background-clip: text;
				transition: background-color 5000s ease-in-out 0s;
				-webkit-box-shadow: inset 0 0 20px 20px transparent;
			}

			&[color='dark'] input:-webkit-autofill,
			&[color='dark'] input:-webkit-autofill:hover,
			&[color='dark'] input:-webkit-autofill:focus,
			&[color='dark'] input:-webkit-autofill:active {
				-webkit-text-fill-color: #f9f9f9;
			}

			&[color='light'] input:-webkit-autofill,
			&[color='light'] input:-webkit-autofill:hover,
			&[color='light'] input:-webkit-autofill:focus,
			&[color='light'] input:-webkit-autofill:active {
				-webkit-text-fill-color: #646464;
			}

			&[size='small'] {
				height: 24px;
				line-height: 15px;
				font-size: 11px;
				font-weight: 400;
				.mat-form-field-flex {
					padding-left: 8px;
				}

				.mat-form-field-suffix {
					margin-right: 8px;
				}
			}
			&[size='medium'] {
				height: 32px;
				line-height: 15px;
				font-size: 11px;
				.mat-form-field-flex {
					padding-left: 12px;
				}

				.mat-form-field-suffix {
					margin: 0 12px 0 0;
				}

				.mat-form-field-label-wrapper {
					padding-top: 0;

					.mat-form-field-label {
						padding-top: 8.5px;
					}
				}
			}
			&[size='large'] {
				height: 46px;
				font-size: 14px;

				.mat-form-field-prefix {
					margin-right: 0;
				}

				.mat-input-element {
					padding-bottom: 6px;

					&[type='password']::-ms-reveal,
					&[type='password']::-ms-clear {
						display: none;
					}
				}

				.mat-form-field-flex {
					padding: 0 12px;
				}

				.mat-form-field-infix {
					margin-top: 20px;
				}

				.mat-form-field-label-wrapper {
					padding-top: 0;

					.mat-form-field-label {
						padding-top: 8px;
					}
				}

				.mat-form-field-subscript-wrapper {
					margin: 0;
					top: 50px;
				}
			}
		}
	}
}
