.login-logotype {
	max-width: 100%;
	height: auto;
	display: block;
	width: 500px;
	margin-bottom: 50px;
	align-self: center;
}

.app {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.app-header {
	background: #010101;
}

.app-header__container {
	max-width: 960px;
	margin-left: auto;
	margin-right: auto;
}

.app-header__nav-links {
	padding: 12px;
}

.app-header__nav-link + .app-header__nav-link {
	margin-left: 24px;
}

.app-content {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 48px;
}

.app-footer {
	height: 30px;
	line-height: 30px;
	color: lighten(#000, 30%);
	text-align: center;
	background-color: rgba(#fff, 0.85);
}

.page-heading {
	height: 100px;
	width: 100%;
	display: grid;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-size: 4rem;
	padding: 0;
	margin: 0;
	background-color: rgba(#000, 0.05);
}

.page-content {
	&-all-centered {
		display: grid;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 50px - 100px - 30px);
		overflow: auto;
		.grid-form-column {
			transform: translateY(-50px);
		}
	}
}

.app-content-container {
	background-color: rgba(#000e26, 0.88);
	max-width: calc(920 / 1920 * 100%);
	min-width: 700px;
	padding: 70px 85px;
	margin-left: auto;
	margin-right: auto;
}

.app-description {
	color: #fff;
}

.app-description-text {
	line-height: 1.5;
}

.app-description-copyright {
	color: #fff;
}

.app-form-divider {
	height: 100%;
	background-color: #fff;
	width: 2px;
}
