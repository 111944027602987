@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import 'styles/helpers';
@import 'styles/scrollable';
@import 'styles/slds.scss';

@import 'styles/base';
@import 'styles/global';

@import 'styles/forms';

// ds styles
@import 'styles/ds/ds-global.scss';
@import 'styles/ds/ds-form.scss';

// overrides
@import 'styles/overrides/index.scss';

// theme
@import 'styles/theme/sso-theme';

// layout attributes
@import 'styles/layout';

html.sso {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	font-size: 16px;

	body {
		margin: 0;
		outline: none !important;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		font-family: 'Open Sans', Open Sans, Arial, sans-serif;
		animation: bodyOpacity 0.5s linear;
	}

	@keyframes bodyOpacity {
		0% {
			opacity: 0;
		}
		99% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
