@mixin flex() {
	display: flex;
	min-width: 0;
}

[horizontal='evenly'] {
	@include flex();
	justify-content: space-evenly;
}

[horizontal='around'] {
	@include flex();
	justify-content: space-evenly;
}

[horizontal='between'] {
	@include flex();
	justify-content: space-between;
}

[horizontal='center'] {
	@include flex();
	justify-content: center;
}

[horizontal='start'] {
	@include flex();
	justify-content: flex-start;
}

[horizontal='end'] {
	@include flex();
	justify-content: flex-end;
}

[vertical='center'] {
	@include flex();
	align-items: center;
}

[vertical='start'] {
	@include flex();
	align-items: flex-start;
}

[vertical='end'] {
	@include flex();
	align-items: flex-end;
}

[direction='column'] {
	@include flex();
	flex-direction: column;
}

[direction='column-reverse'] {
	@include flex();
	flex-direction: column-reverse;
}
