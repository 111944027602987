.validation-message {
	margin-top: 0.3rem;
	color: #f26175;
	font-size: 1.3rem;
}

.error-message {
	color: #f26175;
}

.form__layout-grid {
	display: grid;
	grid-row-gap: 35px;
	width: 300px;
}

.form__header {
	font-size: 24px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
	margin-bottom: 48px;
}

.form-control__label {
	opacity: 0.35;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 0;
	left: 8px;
	top: 8px;
	position: absolute;
}

.form-control__label-invalid {
	opacity: 1;
	color: #f26175;
}

.form-control__label--focus {
	visibility: visible;
	opacity: 1;
	font-size: 14px;
	left: 0;
	top: -24px;
	transition: 0.5s;
}

.form-control__input {
	border: none;
	background: none;
	font-size: 18px;
	display: block;
	border-radius: 0;
	width: 100%;
	border-bottom: 2px solid #fff;
	color: #fff;
	padding: 8px;

	&:focus {
		outline: none;
		box-shadow: none;
	}
	&::placeholder {
		opacity: 0.35;
		color: #fff;
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 0;
		left: 8px;
		top: 8px;
		position: absolute;
		transition: 0.5s;
	}
}

.form-control__input-invalid {
	border-bottom-color: #f26175;
}

.form-control__submit {
	text-transform: uppercase;
	padding: 8px 30px;
	color: #fff;
	font-size: 18px;
	border: 2px solid #fff;
	border-radius: 125px;
	background: transparent;
	cursor: pointer;
	&[disabled] {
		opacity: 0.35;
		cursor: not-allowed;
	}
}

.form-control__static {
	font-weight: 600;
	color: #fff;
	padding: 4px 8px 4px 4px;
	line-height: 2;
	border-bottom: 2px solid #fff;
}
