* {
	text-shadow: none !important;
	box-sizing: border-box;
}

a {
	font-weight: 600;
	color: #fff;
	text-decoration: underline;
	&:hover,
	&:focus,
	&:active {
		color: #fff;
	}
}
