.eff {
	.eff-form-field {
		&.mat-form-field {
			width: 100%;
			outline: none;
		}
		.mat-form-field-required-marker {
			display: none;
		}
	}
}
