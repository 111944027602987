// 'eff-styles form-field textarea
// to add label use mat-label
// to set color theme use attributes 'color' (dark, light)
$blue-color: #3c8fee;

.eff {
	.eff-form-field {
		&.eff-form-field--textarea {
			&[appearance='fill'] {
				border-radius: 4px;
				border: none;
				transition: 200ms;

				.mat-form-field-flex {
					padding: 0 0 0 12px;
					align-items: center;
					height: 100%;
					border-radius: 4px;
				}

				.mat-form-field-label-wrapper {
					top: 2px;
					margin-top: 0;
				}

				.mat-form-field-label-wrapper .mat-form-field-label {
					display: flex;
				}

				.mat-input-element {
					padding-top: 16px;
					margin: 0;
					line-height: initial;
				}

				.mat-form-field-wrapper {
					height: 100%;
					padding-bottom: 0;
				}

				.mat-form-field-suffix {
					margin-left: 4px;
				}

				.mat-form-field-prefix {
					margin-right: 4px;
				}

				.mat-form-field-infix {
					display: flex;
					border-top: 0;
					width: 100%;
					padding: 0;
					height: 100%;
				}
				.mat-form-field-underline {
					display: none;
				}
			}

			&[color='dark'] {
				background-color: #383b3f;
				color: #f9f9f9;

				&.mat-form-field-invalid {
					background: rgba(235, 87, 87, 0.2);
					outline: 2px solid #eb5757;
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: #a8a8a8;
				}

				.mat-input-element {
					color: #f9f9f9;

					&::placeholder {
						color: #a1a1a2;
					}
				}

				&:hover {
					background-color: #414449;
				}

				&:focus {
					color: #a1a1a2;
					outline: 2px solid $blue-color;
				}

				&.mat-focused {
					outline: 2px solid $blue-color;
				}

				.mat-form-field-label {
					color: #a1a1a2;
				}
			}

			&[color='light'] {
				color: #646464;
				background-color: #ededed;
				&.mat-form-field.mat-focused .mat-form-field-label {
					color: #646464;
				}

				&.mat-form-field-invalid {
					background: rgba(235, 87, 87, 0.2);
					outline: 2px solid #eb5757;
				}

				.mat-form-field-prefix,
				.mat-form-field-suffix {
					color: #646464;
				}

				&.mat-focused {
					color: #a1a1a2;
					outline: 2px solid $blue-color;
				}

				&:hover {
					color: #383b3f;
					outline: 1px solid #d8d8d8;
				}
			}

			textarea {
				min-height: 19px;
			}
		}
	}
}
