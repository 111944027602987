.sso {
	.mat-dialog-close-action {
		display: none;
	}
	.mat-dialog-container {
		border-radius: 8px;
		height: inherit;
		color: #292d32;

		.mat-dialog-close-action {
			display: block;
		}

		&.mat-dialog-container-theme--dark {
			background-color: #465053;
			color: #fff;

			.mat-dialog-close-action {
				color: #fff;
			}
		}

		.mat-dialog-component {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.mat-dialog-header {
			display: flex;
			align-items: center;
			flex-shrink: 0;
		}

		.mat-dialog-header + .mat-dialog-content {
			margin-top: 12px;
		}

		.mat-dialog-title {
			margin-bottom: 0;
		}

		.mat-dialog-close-action {
			padding: 0;
			width: 32px;
			height: 32px;
			flex-shrink: 0;
			margin-left: auto;
		}

		.mat-dialog-close-icon {
			width: 24px;
			height: 24px;
			font-size: 24px;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.mat-dialog-content {
			flex-grow: 1;
			border-top: 4px solid transparent;
			padding-top: 2px;

			&::-webkit-scrollbar {
				width: 10px;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background: #c7ced0;
				border-radius: 4px;
			}

			scrollbar-color: #c7ced0 #f9f9f9;
			overflow: overlay;
		}

		.mat-dialog-actions {
			flex-shrink: 0;
			min-height: auto;
			margin: 0;
			padding-top: 12px;
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;

			.mat-button-base + .mat-button-base,
			.mat-mdc-button-base + .mat-mdc-button-base {
				margin-left: 12px;
			}
		}
	}

	.ds-mat-dialog--inverted {
		.mat-dialog-container {
			background: #465053;
		}
	}
}
