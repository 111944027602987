.eff {
	.mat-tooltip {
		border-radius: 4px;
		word-wrap: break-word;
		font-size: 10px;
		line-height: 14px;
		padding: 4px 8px;
		margin: 2px;
		background-color: #fff;
		color: #383b3f;
	}
}
