.ds {
	display: flex;
	justify-content: center;
	height: 100vh;
	background: #222426;
	color: #fff;
	//font-family: 'Open Sans', Arial, sans-serif;

	.ds-content-wrapper {
		margin: auto;
		background: #222426;
		border-radius: 8px;
		padding: 42px 64px;
	}

	.ds-logo {
		width: 130px;
		height: 28px;
	}

	.ds-container {
		width: 400px;
	}

	.ds__password-visibility-button {
		width: 20px;
		cursor: pointer;
		color: #ffffff;
	}

	a {
		color: #86b7e9;
		font-weight: 400;
		text-decoration: none;
	}
}

@media screen and (max-width: 568px) {
	.ds {
		background: transparent;

		.ds-content-wrapper {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 48px 24px 32px;
			box-shadow: none;
		}

		.ds-container {
			width: 100%;
		}
	}
}
