.sso {
	button[mat-raised-button],
	button[mat-stroked-button],
	button[mat-button][sso-btn] {
		padding-right: 24px;
		padding-left: 24px;
		border-radius: 6px;
	}

	// text style button
	button[mat-button][sso-btn-link] {
		min-width: auto;
		padding: 0;
		color: #3c8fee;
		font-weight: 400;
		line-height: inherit;

		&:hover {
			text-decoration: underline;
		}

		a {
			color: #3c8fee;
		}
	}

	// text style button
	button[mat-button][sso-btn] {
		line-height: 40px;
	}

	// orange button
	button[mat-button][color='orange'] {
		background: #fbac3d;
		color: #fff;

		&[disabled] {
			opacity: 0.5;
			color: #fff;
		}
	}

	// secondary button
	button[mat-stroked-button] {
		border: 1px solid #4a4d52;
		line-height: 38px;
		box-shadow: none;

		&:hover {
			border: 1px solid rgba(81, 85, 90, 0.5);
		}

		&:focus,
		&:active,
		&:visited {
			border: 1px solid rgba(81, 85, 90, 0.5);
		}

		&[disabled] {
			border: none;
			background: #e5e7e7;
		}
	}

	button[mat-stroked-button][color='white'] {
		color: #fff;
		border-color: #fff;
	}

	button[mat-stroked-button][color='grey'] {
		background: #5d6a6e;
		border-color: #343c3e;
		color: #fff;
	}

	// main button
	button[mat-raised-button] {
		line-height: 40px;
		box-shadow: none;

		&:hover {
			background-color: #3c8fee;
		}

		&:focus {
			background-color: #0a69d5;
		}

		&[disabled] {
			filter: none;
			background: #a8a8a8;
			color: rgba(255, 255, 255, 0.75);
		}
	}

	// dominant
	button[mat-raised-button][color='dominant'] {
		color: #fff;
		padding-right: 12px;
		padding-left: 12px;
		border-radius: 8px;
		background: #465053;

		&[disabled] {
			background: #4650534d;
		}
	}
}
