.sso {
	.cdk-overlay-container .mat-snack-bar-container {
		display: flex;
		align-items: center;
		width: max-content;
		min-width: 448px;
		min-height: 56px;
		background-color: #fff;
		filter: drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.1));
		border-radius: 16px;
		color: #000;
		padding: 8px 24px;
	}

	.cdk-overlay-container .mat-snack-bar-container > * {
		width: 100%;
	}
}
