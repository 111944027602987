.eff {
	.eff-checkbox {
		line-height: 1;

		.mat-checkbox-inner-container {
			width: 12px;
			height: 12px;
			border-radius: 2px;
			margin-right: 7px;
		}

		.mat-checkbox-layout {
			max-width: 100%;
			align-items: center;
			margin-bottom: 0;
		}

		.mat-checkbox-label {
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			white-space: initial;
			text-align: left;
		}

		.mat-checkbox-ripple {
			left: calc(50% - 13px);
			top: calc(50% - 13px);
			height: 26px;
			width: 26px;
			border-radius: 50%;
		}

		&.mat-checkbox-disabled {
			opacity: 0.5;
		}

		.mat-checkbox-frame {
			background-color: transparent;
		}

		// dark scheme
		&[color='dark'] {
			.mat-checkbox-frame {
				border-color: #cccccc;
			}

			&.mat-checkbox-checked .mat-checkbox-background {
				background: #cccccc;
			}

			&:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
				background-color: #cccccc14;
			}

			.mat-checkbox-label {
				color: #ffffff;
			}

			.mat-checkbox-checkmark-path {
				stroke: #222426 !important;
			}
		}

		// light scheme
		&[color='light'] {
			.mat-checkbox-frame {
				border-color: #222426;
			}

			&.mat-checkbox-checked .mat-checkbox-background {
				background: #222426;
			}

			&:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
				background-color: #22242614;
			}

			.mat-checkbox-label {
				color: #222426;
			}

			.mat-checkbox-checkmark-path {
				stroke: #cccccc !important;
			}
		}

		// disable ripple
		&[disableripple] {
			.mat-ripple.mat-checkbox-ripple {
				display: none;
			}
		}
	}
}
