.ds-form {
	text-align: center;
	margin: auto;
	width: 100%;

	.ds-form__field-wrapper {
		position: relative;
		width: 100%;
		margin-bottom: 24px;
	}

	.ds-form__static-label {
		font-weight: 400;
		color: #fff;
		margin-bottom: 8px;
		padding: 0 10px;
		text-align: initial;
	}

	.ds-form__field-wrapper .ds-form__input,
	.ds-form__field-wrapper .ds-form__textarea {
		width: 100%;
		border: 1px solid transparent;
		padding: 0 10px;
		transition: all 0.3s linear;
		outline: none;
		border-radius: 2px;
	}

	.ds-form__field-wrapper .ds-form__input {
		height: 46px;
		background: #383b3f;
	}

	.ds-form__field-wrapper .ds-form__textarea {
		padding-top: 8px;
	}

	.ds-form__field-wrapper .ds-form__label {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: -4px;
		padding: 0 4px;
		margin: 10px;
		transition: all 0.3s ease;
		background: #fff;
		font-weight: 400;
		color: #74777a;
	}

	.ds-form__field-wrapper .ds-form__input:focus {
		border: 2px solid #0070d2;
	}

	.ds-form__field-wrapper.ds-form__field-invalid .ds-form__input {
		border-color: #e46868;
	}

	/* label color on filled state */
	.ds-form__static-label--invalid,
	.ds-form__field-wrapper.ds-form__field-invalid .ds-form__input ~ .ds-form__label,
	.ds-form__field-wrapper.ds-form__field-invalid .ds-form__textarea ~ .ds-form__label {
		color: #e46868;
	}

	/* label goes up when input is focused OR filled */
	.ds-form__field-wrapper .ds-form__input:-webkit-autofill ~ .ds-form__label,
	.ds-form__field-wrapper .ds-form__input:focus ~ .ds-form__label,
	.ds-form__field-wrapper .ds-form__input:not(:placeholder-shown) ~ .ds-form__label,
	.ds-form__field-wrapper .ds-form__textarea:focus ~ .ds-form__label,
	.ds-form__field-wrapper .ds-form__textarea:not(:placeholder-shown) ~ .ds-form__label {
		font-size: 12px;
		transform: translateY(-17px);
	}

	/* label color on focused state */
	.ds-form__field-wrapper .ds-form__input:focus ~ .ds-form__label,
	.ds-form__field-wrapper .ds-form__textarea:focus ~ .ds-form__label {
		//color: #ff6291;
	}

	/* hide default arrow on <select> */
	.ds-form__field-wrapper select.ds-form__input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		cursor: pointer;
	}

	.ds-form__field-wrapper select.ds-form__input::-ms-expand {
		display: none; /* Remove default arrow in Internet Explorer 10+ */
	}

	/* validation messages */
	.ds-form__validation-messages {
		margin-top: 4px;
		font-size: 12px;
		text-align: left;
		padding-left: 10px;
	}

	.ds-form__error-message {
		color: #e46868;
	}

	.ds-form__hint-message {
		color: #fff;
	}
}
