.eff {
	// text align
	.h-text-align--center {
		text-align: center;
	}

	// text transform
	.h-text-transform--uppercase {
		text-transform: uppercase;
	}

	// font weight
	.h-text-weight--bold {
		font-weight: 700;
	}

	.h-text-weight--light {
		font-weight: 300;
	}

	// font size
	.h-text-size--x-small {
		font-size: 11px;
	}

	.h-text-size--small {
		font-size: 12px;
	}

	.h-text-size--medium {
		font-size: 16px;
	}

	.h-text-size--x-medium {
		font-size: 20px;
	}

	.h-text-size--large {
		font-size: 24px;
	}

	// color
	.h-color--light {
		color: #fff;
	}
}
